import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Site, AccountDropdown } from 'tabler-react';
import { Dropdown } from 'react-bootstrap';
import { APP_NAME } from '../config/const';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { deleteFetch } from 'api/fetch';

type Props = {
  isAdmin: boolean,
}

export const Header: React.FC<Props> = (props) => {
  const { id } = useParams<Record<string, string | undefined>>()
  const history = useHistory();

  const logout = () => {
    deleteFetch(`/logout`).then(res => {
      history.push('/login');
    });
  }

  return (
    <>
    <Site.Header>
      <img src="/static/images/logo.png" height="30" alt={APP_NAME} />

      <Dropdown className="ml-auto">
        <Dropdown.Toggle variant="secondary" id="navi-menu">メニュー</Dropdown.Toggle>
        <Dropdown.Menu>
          {(id) &&
          <>
          <Dropdown.Item onClick={() => history.push(`/accounts/${id}`)}>ファイル一覧</Dropdown.Item>
          <Dropdown.Item onClick={() => history.push(`/accounts/${id}/create`)}>ファイルをアップロード</Dropdown.Item>
          </>
          }
          <Dropdown.Divider />
          {(props.isAdmin) &&
          <Dropdown.Item onClick={() => history.push('/admin')}>アカウント一覧</Dropdown.Item>
          }
          <Dropdown.Item onClick={logout}>ログアウト</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </Site.Header>
    </>
  );
}
