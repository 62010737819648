import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Page, Dimmer } from 'tabler-react';
import { ValidationForm, TextInput, SelectGroup, Checkbox, Radio } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';
import { Grid } from "tabler-react";

import { Account as Response } from 'models/account';
import { getFetch, postFetch } from 'api/fetch';
import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Show = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);

  React.useEffect(() => {
    setLoading(true)
    getFetch(`/admin/accounts/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      setLoading(false)
    });
  }, []);

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();
    setLoading(true)

    let formData = new FormData();
    formData.append('password', values.password);

    postFetch(`/admin/accounts/${id}`, formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'パスワード変更失敗しました', 3000);
        setLoading(false)
      } else {
        history.push('/admin');
      }
    });
  }

  return (
    <>
    <Header isAdmin={true} />
    <Page.Content title="アカウントのパスワード変更">
      <Dimmer active={loading} loader={loading}>
        <ValidationForm onSubmit={handleSubmit}>
          <div className="form-group required">
            <label className="form-label" htmlFor="id">アカウントID</label>
            <div>{id}</div>
          </div>

          <div className="form-group">
            <label className="form-label" htmlFor="password">パスワード</label>
            <TextInput name="password" id="password" type="password"
              required
              minLength={8}
              maxLength={20}
              pattern={`^[0-9a-zA-Z]+$`}
              errorMessage={{
                required: "パスワードを入力してください",
                minLength: "8文字以上で入力してください",
                maxLength: "20文字まで入力してください",
                pattern: "半角英数字のみ入力してください",
              }}
            />
          </div>

          <Grid.Row className="py-3">
            <Grid.Col width={3}>
              <button type="button" className="btn btn-secondary btn-block" onClick={() => history.push('/admin')}>キャンセル</button>
            </Grid.Col>
            <Grid.Col width={9}>
              <button type="submit" className="btn btn-primary btn-block">変更</button>
            </Grid.Col>
          </Grid.Row>
        </ValidationForm>
        </Dimmer>
     </Page.Content>
    <Footer />
    </>
  );
};
