import React from 'react';
import { Site } from 'tabler-react';
import { APP_NAME } from '../config/const';
import BackToTop from 'react-back-top';

import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

export const Footer = () => {
  return (
    <>
    <Site.Footer copyright={
      <>
      Copyright © 2021 {APP_NAME}. All rights reserved.
      </>
    }
    />
    <NotificationContainer />
    <BackToTop icon="fa fa-angle-up" background="#CD201F" />
    </>
  );
}
