import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Page, Button, Card, Dimmer, Grid, Alert } from 'tabler-react';
import { ValidationForm, TextInput, SelectGroup, Checkbox, Radio } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader';
import { IFileWithMeta, IDropzoneProps } from 'react-dropzone-uploader/dist/Dropzone';

import { postFetch } from 'api/fetch';
import { Header } from 'views/_header';
import { Footer } from 'views/_footer';

export const Create = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const history = useHistory();

  const getUploadParams: IDropzoneProps['getUploadParams'] = ({ meta, file }: { meta: any, file: any }) => {
    const body = new FormData()
    body.append('file', file)
    return { url: `/api/accounts/${id}`, body }
  }

  const handleChangeStatus = ({ meta, file }: { meta: any, file: any }, status: any) => {
    if (status === 'done') {
      NotificationManager.success('', `ファイル(${meta.name})をアップしました`, 3000);
    }
  }

  const handleSubmit: IDropzoneProps['onSubmit'] = (files, allFiles) => {
    files.map(f => f.restart())

//    console.log(files.map(f => f.meta))
//    allFiles.forEach(f => f.remove())
  }

//  const handleSubmit = (files: IFileWithMeta[], allFiles: IFileWithMeta[]) => {
//    console.log(files.map(f => f.meta))
//    allFiles.forEach(file => (
//      file.restart()
      //file.remove()
//    ))
//  }

  return (
    <>
    <Header isAdmin={false} />
    <Page.Content title="ファイルをアップロード" subTitle={id}>
      <Dropzone
        getUploadParams={getUploadParams}
        onChangeStatus={handleChangeStatus}
        //accept="image/*,audio/*,video/*"
        //onSubmit={handleSubmit}
        styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
        maxFiles={10}
        multiple={true}
        canCancel={true}
        maxSizeBytes={3145728000}
        //autoUpload={false}
        inputContent="ファイルを選択してください"
        inputWithFilesContent="追加する"
      />
      <Alert type="danger" className="my-3">データをアップする場合には1データ3GB以内でお願いいたします。</Alert>
      <Button block color="primary" className="mt-3" RootComponent="a" href={`/accounts/${id}`}>一覧へ戻る</Button>
    </Page.Content>
    <Footer />
    </>
  );
};
