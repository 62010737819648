import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Analytics from 'react-router-ga';
import { LastLocationProvider } from 'react-router-last-location';

import { Login } from 'views/login';
import { Index as AccountsIndex } from 'views/admin/accounts/index';
import { Create as AccountsCreate } from 'views/admin/accounts/create';
import { Show as AccountsShow } from 'views/admin/accounts/show';
import { Index as FilesIndex } from 'views/files/index';
import { Create as FilesCreate } from 'views/files/create';
import { Index as ErrorIndex } from 'views/error/index';

function App() {
  return (
    <BrowserRouter>
      <LastLocationProvider>
        <Analytics id="UA-179064693-1" debug>
          <Switch>
            <Route path="/login" component={Login} />
            <Route path="/admin/accounts/create" component={AccountsCreate} />
            <Route path="/admin/accounts/:id" component={AccountsShow} />
            <Route path="/admin" component={AccountsIndex} />
            <Route path="/accounts/:id/create" component={FilesCreate} />
            <Route path="/accounts/:id" component={FilesIndex} />
            <Route exact path="/" component={Login} />
            <Route component={ErrorIndex} />
          </Switch>
        </Analytics>
      </LastLocationProvider>
    </BrowserRouter>
  );
}

export default App;
