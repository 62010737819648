import React from 'react';
import { Modal } from 'react-bootstrap';
import { Button, Dimmer, Table, Form } from 'tabler-react';
import moment from 'moment';

import { getFetch, postFetch } from 'api/fetch';
import { FileComments as Response } from 'models/file_comment';

type Props = {
  setShow: React.Dispatch<React.SetStateAction<boolean>>,
  show: boolean,
  id?: string,
  fid?: string,
}

export const Comments: React.FC<Props> = (props) => {
  const [loading, setLoading] = React.useState(true);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const setShow = props.setShow;
  const show = props.show;
  const [comment, setComment] = React.useState('');

  React.useEffect(() => {
    if (!show) {
      return;
    }

    showComments();
  }, [show]);

  const showComments = () => {
    setLoading(true)
    getFetch(`/accounts/${props.id}/${props.fid}/comments`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      setLoading(false)
    });
  }

  const handleClose = () => setShow(false);

  const handleChange = (e: any) => {
    setComment(e.target.value);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (comment === '') {
      return;
    }

    let formData = new FormData();
    formData.append('comment', comment);

    postFetch(`/accounts/${props.id}/${props.fid}/comments`, formData).then(res => {
      if (!!res?.error) {
        return;
      }

      setComment('');
      showComments();
    });
  };


  return (
    <>
    <Modal show={show} onHide={handleClose} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>コメント一覧</Modal.Title>
      </Modal.Header>
      <Modal.Body>
      <Dimmer active={loading} loader={loading}>
        {(response && response.length > 0) ?
          <Table>
            <Table.Header>
              <Table.ColHeader>#</Table.ColHeader>
              <Table.ColHeader>コメント</Table.ColHeader>
              <Table.ColHeader>コメント日時</Table.ColHeader>
            </Table.Header>
            <Table.Body>
              {response.map(comment => (
                <Table.Row>
                  <Table.Col></Table.Col>
                  <Table.Col>{comment.comment}</Table.Col>
                  <Table.Col>{moment.unix(comment.updated_at).format('YYYY/MM/DD HH:mm')}</Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        :
          <>
          コメントがまだありません
          </>
        }
      </Dimmer>
      </Modal.Body>
      <Modal.Footer>
        <Form.InputGroup
          append={
            <Button color="success" type="submit" onClick={handleSubmit}>送信</Button>
          }
        >
          <Form.Input value={comment} onChange={handleChange} maxLength={100} placeholder="コメントを入力してください" />
        </Form.InputGroup>

        <Button color="secondary" onClick={handleClose}>キャンセル</Button>
      </Modal.Footer>
    </Modal>
    </>
  );
}
