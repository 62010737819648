import enums from './enums.json';

export const APP_NAME = 'PLUS Cloud Data System';
export const BASE_URL = '';
export const Enums = enums.enums;

export const GetEnumsName = (model: any, id: number) => {
    let name = '';
    Object.keys(model).map((key) => {
        if (model[key]['id'] === id) {
            name = model[key]['ja'];
            return false;
        }
    });
    return name;
}
