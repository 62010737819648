import { BASE_URL } from '../config/const';

const API_URL = `${BASE_URL}/api`;

export const getFetch = async (endpoint: string): Promise<any> => {
    return await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
    })
    .then(res => checkStatus(res))
    .then(res => res.json())
    .then(res => {
        return res;
    })
    .catch(error => {
        return {
            error: error
        }
    })
}

export const postFetch = async (endpoint: string, formData: any): Promise<any> => {
    return await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
        method: 'POST',
        body: formData
    })
    .then(res => checkStatus(res))
    .then(res => res.json())
    .then(res => {
        return res;
    })
    .catch(error => {
        return {
            error: error
        }
    })
}

export const putFetch = async (endpoint: string, formData: any): Promise<any> => {
    return await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
        method: 'PUT',
        body: formData
    })
    .then(res => checkStatus(res))
    .then(res => res.json())
    .then(res => {
        return res;
    })
    .catch(error => {
        return {
            error: error
        }
    })
}

export const deleteFetch = async (endpoint: string): Promise<any> => {
    return await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
        method: 'DELETE'
    })
    .then(res => checkStatus(res))
    .then(res => res.json())
    .then(res => {
        return res;
    })
    .catch(error => {
        return {
            error: error
        }
    })
}

const checkStatus = (res: any) => {
    if (res.ok) {
        return res;
    } else {
        if (res.status === 403) {
            window.location.href = `${BASE_URL}/login`;
        }

        // var error = new Error(res.status);
        var error = res.status;
        throw error;
    }
}

export const getFetchBlob = async (endpoint: string): Promise<any> => {
    return await fetch(`${API_URL}${endpoint}`, {
        credentials: 'include',
    })
    .then(res => checkStatus(res))
    .then(res => res.blob())
    .then(res => {
        return res;
    })
    .catch(error => {
        return {
            error: error
        }
    })
}
