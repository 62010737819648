import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'tabler-react';

type Props = {
  href?: string,
  onClick?: React.EventHandler<React.SyntheticEvent>,
}

export const CreateButton: React.FC<Props> = (props) => {
  const history = useHistory();
  const href = props.href;

  const handleClick = (e: any, href: string | undefined) => {
    e.preventDefault();
    if (href) {
      history.push(href);
    }
  };

  return (
    <button className="btn btn-danger btn-lg btn-icon btn-fab rounded-circle" onClick={(e) => handleClick(e, href)}>
      <Icon prefix="fa" name="plus" />
    </button>
  );
}
