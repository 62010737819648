import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Page, Dimmer, Table, Icon } from 'tabler-react';
import moment from 'moment';
import NumericLabel from 'react-pretty-numbers';
import DataTable from 'react-data-table-component';
import { confirm } from 'react-bootstrap-confirmation';

import { getFetch, deleteFetch } from 'api/fetch';
import { FilesIndex as Response } from 'api/files';
import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { Comments } from 'views/files/_comments';
import { CreateButton } from 'views/_create_button';

export const Index = () => {
  const { id } = useParams<Record<string, string | undefined>>()
  const [show, setShow] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const history = useHistory();
  const [fid, setFid] = React.useState('');
  const [isAdmin, setIsAdmin] = React.useState(false);

  React.useEffect(() => {
    if (show) {
      return;
    }

    showFiles();
  }, [show]);

  const showFiles = () => {
    setLoading(true)
    getFetch(`/accounts/${id}`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      if (res.my.role === 9 || res.my.role === 10) {
        setIsAdmin(true);
      }
      setLoading(false)
    });
  }

  const option = {
    'justification':'C',
    'percentage':false,
    'commafy':true,
    'shortFormat':true,
    'shortFormatMinValue': 100000,
    'shortFormatPrecision': 1,
  };

  const columns = [
    {
      name: '',
      selector: '',
      sortable: false,
      wrap: false,
      maxWidth: '30px',
      cell: (row: any) =>
        <>
        <a href={`/api/accounts/${row.account_id}/${row.id}`} target="_blank" download={row.name}><Icon prefix="fa" name="cloud-download" className="fa-lg text-success" /></a>

        {(isAdmin) &&
        <a href="#" className="ml-3" onClick={(e) => deleteConfirm(e, row.id)}><Icon prefix="fa" name="trash" className="fa-lg text-danger" /></a>
        }
        </>
      ,
    },
    {
      name: 'ファイル名',
      selector: 'name',
      sortable: false,
      wrap: false,
      //allowOverflow: true,
      minWidth: '400px',
      cell: (row: any) => <>{row.name}&nbsp;<span className="text-muted"><NumericLabel params={option}>{row.size}</NumericLabel></span></>
    },
    {
      name: 'アカウント',
      selector: 'account',
      sortable: true,
      cell: (row: any) => <>{(row.uploaded_by.length < 8) ? '管理者' : row.uploaded_by}</>,
    },
    {
      name: '確認',
      selector: 'downloaded_at',
      sortable: false,
      maxWidth: '10px',
      cell: (row: any) => row.downloaded_at ? <span className="text-success">済</span> : <span className="text-muted">未</span>,
    },
    {
      name: '最新コメント',
      selector: 'last_comment',
      sortable: false,
      wrap: false,
      minWidth: '200px',
      cell: (row: any) =>
        <>
        <a href="#" className="mr-3" onClick={(e) => handleShow(e, row.id)}><Icon prefix="fa" name="comments" className="fa-lg text-warning" /></a>
        {row.last_comment.substring(0, 9)}
        </>,
    },
    {
      name: '公開日時',
      selector: 'created_at',
      sortable: true,
      width: '150px',
      cell: (row: any) => moment.unix(row.created_at).format('YY/MM/DD HH:mm'),
    },
    {
      name: '最終更新',
      selector: 'updated_at',
      sortable: true,
      width: '150px',
      cell: (row: any) => moment.unix(row.updated_at).format('YY/MM/DD HH:mm'),
    },
  ];

  const handleShow = (e: any, fid: string) => {
    e.preventDefault();
    setFid(fid);
    setShow(true);
  }

  const deleteConfirm = async (e: any, fid: string) => {
    e.preventDefault();
    const result = await confirm('削除しますか？', {'okText': 'は　い', 'cancelText': 'いいえ'});
    if (result) {
      deleteFetch(`/accounts/${id}/${fid}`).then(res => {
        if (!!res?.error) {
          return;
        }

        showFiles();
      });
    }
  };

  return (
    <>
    <Header isAdmin={isAdmin} />
    <Page.Content title="ファイル一覧" subTitle={id}>
      <Dimmer active={loading} loader={loading}>
        {(response?.files && response.files.length > 0) &&
          <>
          <DataTable columns={columns} data={response.files} pagination={true} paginationPerPage={10} noHeader={true} />
          </>
        }
      </Dimmer>
      <CreateButton href={`/accounts/${id}/create`} />
    </Page.Content>
    <Footer />
    <Comments id={id} fid={fid} setShow={setShow} show={show} />
    </>
  );
};
