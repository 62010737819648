import React from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Container, Page, Dimmer, Table, Icon } from 'tabler-react';
import moment from 'moment';
import { confirm } from 'react-bootstrap-confirmation';
import NumericLabel from 'react-pretty-numbers';

import { getFetch, deleteFetch } from 'api/fetch';
import { Accounts as Response } from 'models/account';
import { Header } from 'views/_header';
import { Footer } from 'views/_footer';
import { CreateButton } from 'views/_create_button';

export const Index = () => {
  const [loading, setLoading] = React.useState(true);
  const [response, setResponse] = React.useState<Response | undefined>(undefined);
  const history = useHistory();

  React.useEffect(() => {
    showAccounts();
  }, []);

  const option = {
    'justification':'C',
    'percentage':false,
    'commafy':true,
    'shortFormat':true,
    'shortFormatMinValue': 100000,
    'shortFormatPrecision': 1,
  };

  const showAccounts = () => {
    setLoading(true)
    getFetch(`/admin/accounts`).then(res => {
      if (!!res?.error) {
        return;
      }

      setResponse(res);
      setLoading(false)
    });
  }

  const deleteConfirm = async (e: any, id: string) => {
    e.preventDefault();
    const result = await confirm('このアカウントを削除しますか？', {'okText': 'は　い', 'cancelText': 'いいえ'});
    if (result) {
      deleteFetch(`/admin/accounts/${id}`).then(res => {
        if (!!res?.error) {
          return;
        }

        showAccounts();
      });
    }
  };

  return (
    <>
    <Header isAdmin={true} />
    <Page.Content title="アカウント一覧">
      <Dimmer active={loading} loader={loading}>
        {(response && response.length > 0) &&
          <Table>
            <Table.Header>
              <Table.ColHeader></Table.ColHeader>
              <Table.ColHeader>ID</Table.ColHeader>
              <Table.ColHeader>管理者</Table.ColHeader>
              <Table.ColHeader>トータル・サイズ</Table.ColHeader>
              <Table.ColHeader>作成日時</Table.ColHeader>
              <Table.ColHeader>更新日時</Table.ColHeader>
            </Table.Header>
            <Table.Body>
              {response.map(account => (
                <Table.Row>
                  <Table.Col>
                    <Link to={`/accounts/${account.id}`}><Icon prefix="fa" name="copy" className="fa-lg text-primary" /></Link>
                    <Link to={`/admin/accounts/${account.id}`} className="ml-3"><Icon prefix="fa" name="file" className="fa-lg text-success" /></Link>
                    <a href="#" className="ml-3" onClick={(e) => deleteConfirm(e, account.id)}><Icon prefix="fa" name="trash" className="fa-lg text-danger" /></a>
                  </Table.Col>
                  <Table.Col>{account.id}</Table.Col>
                  <Table.Col>{account.root_id}</Table.Col>
                  <Table.Col><NumericLabel params={option}>{account.total_size}</NumericLabel></Table.Col>
                  <Table.Col>{moment.unix(account.created_at).format('YYYY/MM/DD HH:mm')}</Table.Col>
                  <Table.Col>{moment.unix(account.updated_at).format('YYYY/MM/DD HH:mm')}</Table.Col>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        }
      </Dimmer>
      <CreateButton href="/admin/accounts/create" />
    </Page.Content>
    <Footer />
    </>
  );
};
