import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Container, Grid, Card, Button, Icon } from 'tabler-react';
import { ValidationForm, TextInput } from 'react-bootstrap4-form-validation'
import { NotificationManager } from 'react-notifications';

import { BASE_URL, APP_NAME } from 'config/const';
import { postFetch } from 'api/fetch';
import { Footer } from 'views/_footer';

export const Login = () => {
  const history = useHistory();

  const handleSubmit = (e: any, values: any) => {
    e.preventDefault();

    let formData = new FormData();
    formData.append('account', values.account);
    formData.append('password', values.password);

    postFetch('/login', formData).then(res => {
      if (!!res?.error) {
        NotificationManager.error('', 'アカウントもしくはパスワードが違います。', 3000);
      } else {
        if (res?.role === 9 || res?.role === 10) {
          history.push('/admin');
        } else {
          history.push(`/accounts/${res?.id}`);
        }
      }
    });
  };

  return (
    <>
    <Container className="mt-5">
      <Grid.Row>
        <Grid.Col lg={6} md={8} sm={10} className="mx-auto">
          <Card>
            <Card.Body>
              <img src="/static/images/logo.png" className="img-fluid" alt={APP_NAME} />

              <p className="text-center my-5">
                <span className="text-danger">{APP_NAME}</span> のアカウントでログイン
              </p>

              <ValidationForm onSubmit={handleSubmit}>
                <div className="form-group required">
                  <label className="form-label" htmlFor="account">アカウント</label>
                  <TextInput name="account" id="account" type="text" placeholder="アカウントを入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: 'アカウントを入力してください',
                    }}
                  />
                </div>

                <div className="form-group required">
                  <label className="form-label" htmlFor="password">パスワード</label>
                  <TextInput name="password" id="password" type="password" placeholder="パスワードを入力してください"
                    required
                    maxLength={20}
                    errorMessage={{
                      required: "パスワードを入力してください",
                    }}
                  />
                </div>

                <Button color="primary" type="submit" size="lg" block className="mt-5">ログイン</Button>
              </ValidationForm>
            </Card.Body>
          </Card>
        </Grid.Col>
      </Grid.Row>
    </Container>
    <Footer />
    </>
  );
};
